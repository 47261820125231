import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { JwtModule } from '@auth0/angular-jwt';

import { ApiService } from './services/api.service';
import { ErrorsService } from './services/errors.service';
import { NotificationsService } from './services/notifications.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ErrorsHandler } from './interceptors/errors-handler';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SaasLandingComponent } from './views/pages/saas-landing/saas-landing.component';
import { HeaderComponent } from './views/layout/header/header.component';
import { LoaderComponent } from './views/layout/loader/loader.component';
import { FooterComponent } from './views/layout/footer/footer.component';
import { SidebarComponent } from './views/layout/sidebar/sidebar.component';
import { FunfactComponent } from './views/common/funfact/funfact.component';
import { PartnerComponent } from './views/common/partner/partner.component';
import { AppLandingComponent } from './views/pages/app-landing/app-landing.component';
import { DigitalMarketingComponent } from './views/pages/digital-marketing/digital-marketing.component';
import { ProductLandingComponent } from './views/pages/product-landing/product-landing.component';
import { SoftwareLandingComponent } from './views/pages/software-landing/software-landing.component';
import { BookLandingComponent } from './views/pages/book-landing/book-landing.component';
import { StartupAgencyComponent } from './views/pages/startup-agency/startup-agency.component';
import { PaymentProcessingComponent } from './views/pages/payment-processing/payment-processing.component';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { FeaturesComponent } from './views/pages/features/features.component';
import { ServicesComponent } from './views/pages/services/services.component';
import { GalleryComponent } from './views/pages/gallery/gallery.component';
import { BlogComponent } from './views/pages/blog/blog.component';
import { BlogDetailsComponent } from './views/pages/blog-details/blog-details.component';
import { ContactUsComponent } from './views/pages/contact-us/contact-us.component';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';
import { FaqComponent } from './views/pages/faq/faq.component';
import { ComingSoonComponent } from './views/pages/coming-soon/coming-soon.component';
import { LoginComponent } from './views/pages/login/login.component';
import { SignupComponent } from './views/pages/signup/signup.component';
import { TeamComponent } from './views/pages/team/team.component';
import { PricingComponent } from './views/pages/pricing/pricing.component';
import { ProjectsComponent } from './views/pages/projects/projects.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './views/pages/terms-and-conditions/terms-and-conditions.component';

import { HomeComponent } from './views/pages/home/home.component';
import { PaymentsComponent } from './views/pages/payments/payments.component';
import { PaymentComponent } from './views/pages/payments/payment/payment.component';
import { AirtimeComponent } from './views/pages/payments/utility/airtime/airtime.component';
import { UtilityComponent } from './views/pages/payments/utility/utility.component';

import { SendMomoComponent } from './views/pages/payments/send-momo/send-momo.component';
import { PayChurchComponent } from './views/pages/payments/pay-church/pay-church.component';
import { DataBundleComponent } from './views/pages/payments/data-bundle/data-bundle.component';
import { PayItemComponent } from './views/pages/payments/pay-item/pay-item.component';
import { PayGroupComponent } from './views/pages/payments/pay-group/pay-group.component';
import { PayBillComponent } from './views/pages/payments/pay-bill/pay-bill.component';


export function tokenGetter() {
  return JSON.parse(localStorage.getItem('Paynow'));
}

@NgModule({
  declarations: [
    AppComponent,
    SaasLandingComponent,
    HeaderComponent,
    LoaderComponent,
    FooterComponent,
    SidebarComponent,
    FunfactComponent,
    PartnerComponent,
    AppLandingComponent,
    DigitalMarketingComponent,
    ProductLandingComponent,
    SoftwareLandingComponent,
    BookLandingComponent,
    StartupAgencyComponent,
    PaymentProcessingComponent,
    AboutUsComponent,
    FeaturesComponent,
    ServicesComponent,
    GalleryComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactUsComponent,
    NotFoundComponent,
    FaqComponent,
    ComingSoonComponent,
    LoginComponent,
    SignupComponent,
    TeamComponent,
    PricingComponent,
    ProjectsComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    HomeComponent,
    PaymentsComponent,
    PaymentComponent,
    AirtimeComponent,
    UtilityComponent,
    SendMomoComponent,
    PayChurchComponent,
    DataBundleComponent,
    PayItemComponent,
    PayGroupComponent,
    PayBillComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    }),
    AppRoutingModule
  ],
  providers: [ ApiService, ErrorsService, NotificationsService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: ErrorsHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
