import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SeoService } from 'src/app/services/seo.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-airtime',
  templateUrl: './airtime.component.html',
  styleUrls: ['./airtime.component.scss']
})
export class AirtimeComponent implements OnInit {
  church: any; pay: any; buyer: boolean = false; verify: boolean = false; url = environment;
  constructor(private titleService: Title, public route: ActivatedRoute, private router: Router, public api: ApiService, private seo: SeoService, private notify: NotificationsService) { 
    // this.church = this.route.snapshot.data.church;
    console.log(this.church);
    // if(this.church == null) { this.router.navigate(['/404']); }
    this.titleService.setTitle('Buy Airtime - PayNow Africa');
    this.seo.generateTags({
      title: 'Buy Airtime - PayNow Africa',
      description: 'Buy Airtime From paynow easily',
      image: this.url.app + 'assets/img/app/payment-page.png'
    });
  }

  ngOnInit(): void {
    this.init();
  }

  postPayment() {
    if(!this.buyer) { 
      this.pay.recipient_mobile_network = this.pay.payeroperatorname; 
      this.pay.recipientmobilenumber = this.pay.payermobilenumber;
    }
    // this.showMsg('success', 'Welcome', 'You Are Welcome @' + this.user.FullName);
    this.api.post('paynow/buyairtime',this.pay).subscribe((data: any) => {
      console.info(data);
      this.notify.success({message: data.message || 'Request Sent, Kindly Confirm request from your phone'});
      this.init();
    });
  }

  init() {
    this.pay = { code: '500', source: 'WEBPAY', recipient_mobile_network: '', recipientmobilenumber: '', vouchernumber: '', payeroperatorname: '', payermobilenumber: '', amount: '', userid: '', botid: 'data', date: new Date }
  }

}
