<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Payments</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->


<!-- Start Features Area -->
<section class="features-area saas-features ptb-100" id="features">
    <div class="container">
        <div class="section-title">
            <h2>PayNow</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-holder-box mt-10">
                    <img src="assets/img/paynow/church_pay.png" alt="image">
                    <h4>Pay Church</h4>
                    <p>Pay tithe, offering, donation etc</p>
                    <a [routerLink]="['/payment/pay-church']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                </div>

                <div class="single-holder-box mt-30">
                    <img src="assets/img/paynow/homepage.png" alt="image">
                    <h4>Pay Bill </h4>
                    <p>Pay for Invoice using Invoice Code. </p>
                    <a [routerLink]="['/payment/pay-bill']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                </div>

            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-holder-box mt-50">
                    <img src="assets/img/paynow/receive-payment.png" alt="image">
                    <h4>Pay Store </h4>
                    <p>Pay for Goods and Services etc</p>
                    <a [routerLink]="['/payment/pay-merchant']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                </div>

                <div class="single-holder-box mt-50">
                    <img src="assets/img/paynow/paynow.png" alt="image">
                    <h4>Pay For Item</h4>
                    <p>Pay for Goods and Services etc</p>
                    <a [routerLink]="['/payment/pay-store']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-holder-box mt-10">
                    <img src="assets/img/paynow/group_save.png" alt="image">
                    <h4>Pay Group</h4>
                    <p>Pay Dues, Levies, Welfare, Assessment, Donation etc</p>
                    <a [routerLink]="['/payment/pay-group']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                </div>

                <!-- <div class="single-holder-box mt-50">
                    <img src="assets/img/paynow/airtime.png" alt="image">
                    <h4>Buy Airtime</h4>
                    <p>Schedule all your events in one place.</p>
                    <a [routerLink]="['/payment/airtime']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                </div> -->

                <div class="single-holder-box mt-30">
                    <img src="assets/img/paynow/insurance.png" alt="image">
                    <h4>Pay Insurance</h4>
                    <p>Buy Third party or Comprehensive Motor Insurance </p>
                    <a href="https://saham.paynowafrica.com" target="_blank" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                </div>
            </div>
        </div>
        <br>
        <!-- <p class="text-center">
            <a [routerLink]="['/signup']" routerLinkActive="router-link-active"  class="btn btn-fill">View more features</a>
            <a [routerLink]="['/features']" routerLinkActive="router-link-active"  class="btn btn-primary">View more features</a>
        </p> -->
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>

    <div class="bubble-animate">
        <div class="circle small square1"></div>
        <div class="circle small square2"></div>
        <div class="circle small square3"></div>
        <div class="circle small square4"></div>
        <div class="circle small square5"></div>
        <div class="circle medium square1"></div>
        <div class="circle medium square2"></div>
        <div class="circle medium square3"></div>
        <div class="circle medium square4"></div>
        <div class="circle medium square5"></div>
        <div class="circle large square1"></div>
        <div class="circle large square2"></div>
        <div class="circle large square3"></div>
        <div class="circle large square4"></div>
    </div>
</section>
<!-- End Features Area -->

<!-- Start Features Area -->
<section class="features-area payment-processing-features bg-gray ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="features-holder-content">
                    <div class="section-title">
                        <img src="assets/img/paynow/ecg.png" alt="image">
                        <h2>Pay Utilities</h2>
                        <div class="bar"></div>
                        <p>Pay for ECG, Ghana Water, DSTV, GOTV, etc.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="single-holder-box">
                            <img src="assets/img/paynow/airtime.png" alt="image">
                            <h3>Buy Airtime</h3>
                            <!-- <p>Duis hendrerit eget dolor non lora pulv0inar. In conse.</p> -->
                            <a [routerLink]="['/payment/pay-utility/airtime']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/paynow/airtime.png" alt="image">
                            <h3>Buy Data</h3>
                            <!-- <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p> -->
                            <a [routerLink]="['/payment/pay-utility/data']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="single-holder-box">
                            <img src="assets/img/paynow/utility.png" alt="image">
                            <h3>Pay G. Water</h3>
                            <!-- <p>Duis hendrerit eget dolor non lora pulv0inar. In conse.</p> -->
                            <a [routerLink]="['/payment/pay-utility/gwc']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/paynow/utility.png" alt="image">
                            <h3>Pay ECG</h3>
                            <!-- <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p> -->
                            <a [routerLink]="['/payment/pay-utility/ecg']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <div class="single-holder-box mt-50">
                            <img src="assets/img/paynow/dstv.png" alt="image">
                            <h3>Pay DSTV</h3>
                            <!-- <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p> -->
                            <a [routerLink]="['/payment/pay-utility/dstv']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/paynow/gotv.jpeg" alt="image">
                            <h3>Pay GOTV</h3>
                            <!-- <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p> -->
                            <a [routerLink]="['/payment/pay-utility/gotv']" routerLinkActive="router-link-active" class="btn btn-fill">PayNow</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->


<!-- Start Overview Area -->
<section class="login-area ptb-100" hidden>
    <div class="container">

    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->