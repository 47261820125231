import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SeoService } from 'src/app/services/seo.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-pay-group',
  templateUrl: './pay-group.component.html',
  styleUrls: ['./pay-group.component.scss']
})
export class PayGroupComponent implements OnInit {
  group: any; pay: any; url = environment; verify: boolean = false; otp: any;
  constructor(private titleService: Title, public route: ActivatedRoute, private router: Router, public api: ApiService, private seo: SeoService, private notify: NotificationsService) { 
    this.group = this.route.snapshot.data.group;
    console.log(this.group);
    if(this.group) { 
      this.titleService.setTitle('Pay Group - ' + this.group.name);
      this.seo.generateTags({
        title: 'Pay Group - '+ this.group.name,
        description: 'Read about Paynow Africa',
        image: this.url.imageUrl + this.group.logo || this.url.app + 'assets/img/app/payment-page.png'
      });
    } // else { this.router.navigate(['/404']); }
  }

  ngOnInit(): void {
    this.init();
  }

  getMerchant() {
    this.router.navigate(['payment/pay-group', this.pay.code]);
  }

  sendOtp() {
    this.pay.code = this.group.code;
    const url = 'website/otp/'+this.pay.mobile+'/Pay-Church?code=' +this.group.code;
    this.api.get(url).subscribe((data: any) => {
      // console.info(data); 
      this.otp = data; this.verify = true;
      this.notify.success({message: 'Request Sent, Kindly Confirm Otp Send to your phone' });
    });
  }

  verifyOtp() {
    this.pay.code = this.group.code;
    const url = 'website/otp/verify/'+this.pay.mobile+'/'+this.pay.otp+'?code=' +this.group.code;
    this.api.get(url).subscribe((data: any) => {
      // console.info(data); 
      this.postPayment();
    });
  }

  postPayment() {
    this.pay.code = this.group.code;
    this.api.post('paynow/merchant',this.pay).subscribe((data: any) => {
      // console.info(data);
      this.notify.success({title: 'success', message: 'Request Sent, Kindly Confirm request from your phone' });
      this.init();
      this.router.navigate(['payment/pay-group']);
    });
  }

  init() {
    this.pay = { code: '', type: '', name: 'Website User', email: '', source: 'Payment Link', mobile: '', network: '', amount: '', currency: 'GH¢', service: 'Pay Group', otp: '', reference: '', date: new Date };
    this.verify = false;
  }

}
