<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12" *ngIf="!item?.code">
                <h2>Provide a Valid Invoice Code</h2>
                <br>
                <h3 class="text-white">Receive payments automatically in your corporate bank account within 24 hours from your customers.</h3>
            </div>
            <div class="col-lg-12" *ngIf="item?.code">
                <!-- <h2>{{item.code}} - {{item.name}}</h2> -->
                <br>
                <h3 class="text-white">Pay For Invoice</h3>
                <!-- <h3 class="text-white">{{item?.description}}</h3> -->
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Features Area -->
<section class="features-area payment-processing-features ptb-100">
    <div class="container">

        <div class="row h-100 justify-content-center align-items-center" >
            <div class="col-lg-6 col-md-12">
                <div class="signup-form">

                    <form method="Post" #billForm="ngForm" *ngIf="!item?.code" (ngSubmit)="getItem()">
                        <h3 class="text-center">Provide Invoice Code </h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Item Code" name="code" [(ngModel)]="pay.code" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="billForm.invalid" class="btn btn-primary btn-sm">Verify Invoice Code</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Standard Modal -->
            <ng-template #content role="document" let-modal>
              <div class="modal-header">
                <h5 class="modal-title mt-0">Payment</h5>
                <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>
              </div>
              <div class="modal-body">
                <div class="col-lg-12 col-md-12">
                    <div class="signup-form">
    
                        <form method="Post" #billForm="ngForm" *ngIf="!item?.code" (ngSubmit)="getItem()">
                            <h3 class="text-center">Provide Invoice Code </h3>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Item Code" name="code" [(ngModel)]="pay.code" required>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="text-right">
                                        <button type="submit" [disabled]="billForm.invalid" class="btn btn-primary btn-sm">Verify Invoice Code</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form method="Post" #paymentForm="ngForm" *ngIf="!verify && item?.code" (ngSubmit)="sendOtp()">
                            <h3 class="text-center">Amount {{pay?.amount | currency:'GHC '}}</h3>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="FullName" name="name" [(ngModel)]="pay.name">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="tel" class="form-control" placeholder="Mobile Number" name="mobile" [(ngModel)]="pay.mobile" required>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <select class="form-control" name="provider" [(ngModel)]="pay.network" required>
                                            <option value="" selected hidden>Select Network</option>
                                            <option value="MTN">MTN</option>
                                            <option value="Vodafone">Vadafone</option>
                                            <option value="AirtelTigo">AirtelTigo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="text-right">
                                        <button type="submit" [disabled]="paymentForm.invalid" class="btn btn-fill btn-sm">Verify Mobile Number</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form method="Post" #otpForm="ngForm" *ngIf="verify" (ngSubmit)="verifyOtp()">
                            <h3 class="text-center">Verify Otp</h3>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Otp" name="otp" [(ngModel)]="pay.otp" required>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="text-right">
                                        <button type="submit" [disabled]="otpForm.invalid" class="btn btn-fill btn-md">MAKE PAYMENT</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
              </div>
            </ng-template>
        </div>

        <div class="row h-100 justify-content-center align-items-center" *ngIf="list.length > 0">
            <div class="col-lg-8 col-md-12 text-left " >
                <p class="company-name">{{item.merchant.name}}</p>
                <p class="company">{{item.addresss}}</p>
                <p class="company">{{item.merchant.address}}</p>
                <p class="company">{{item.merchant.email}}</p>
                <p class="company">{{item.merchant.mobile}}</p>
            </div>

            <div class="col-lg-4 col-md-12 text-right">
                <p class="company-name">Invoice</p>
            </div>

            <div class="col-lg-12 col-md-12 hr"><hr></div>

            <div class="col-lg-6 col-md-12" >
                <h5 class="company-font">Bill to</h5>
                <p class="company">{{item.name}}</p>
                <p class="company">{{item.email}}</p>
                <p class="company">{{item.mobile}}</p>
            </div>

            <div class="col-lg-3 col-md-12 text-right" >
                <h5 class="company-font">Invoice #</h5>
                <h5 class="company-font">Invoice Date</h5>
            </div>

            <div class="col-lg-3 col-md-12 text-right" >
                <p class="company">{{item.code}}</p>
                <p class="company">{{item.date | date}}</p>
            </div>

            <!-- <div class="col-lg-12 col-md-12 hr"><hr></div> -->
            <div class="col-lg-12 col-md-12 "><br></div>

            <div class="col-lg-12 col-md-12" >
                <table *ngIf="list.length > 0" id="invoice-table" class="display"  style="width:100%">
                    <thead>
                        <tr class="table-head">
                            <th class="text-center table-font">QTY</th>
                            <th class="table-font">DESCRIPTION</th>
                            <th class="table-font text-right">UNIT PRICE</th>
                            <th class="table-font text-right">TOTAL AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let l of list" class="tr">
                            <td class=" text-center td">{{l.quantity}}</td>
                            <td class="td">{{l.name}}</td>
                            <td class="text-right td">{{l.unit_price}}</td>
                            <td class="text-right td">{{l.amount}}</td>
                        </tr>
                        <tr>
                            <td class="text-right" colspan="3">Subtotal</td>
                            <td class="text-right td-subtotal"> {{total.amountSum.toFixed(3)}}</td>
                        </tr>
                        <tr>
                            <td class="text-right" colspan="3">Sales Tax</td>
                            <td class="text-right td-tax">{{total.taxSum.toFixed(3)}}</td>
                        </tr>
                        <tr>
                            <td class="text-right total " colspan="3"><b>TOTAL</b></td>
                            <td class="text-right td total  td-total"><b>GH¢ {{total.totalSum.toFixed(3)}}</b></td>
                        </tr>
                    </tbody>
                </table> 
            </div>
            
            <!-- <div class="col-lg-12 col-md-12 bottom-hr"><hr></div> -->
            <!-- <br><br>

            <div class="col-lg-7 col-md-12 ">
                
            </div>
            <div class="col-lg-5 col-md-12 text-right total-section" >
                <div class="col-lg-6 col-md-12 text-right " >
                    <h5 class=" company-font total">Invoice Total</h5>
                </div>

                <div class="col-lg-6 col-md-12 text-right" >
                    <p class="total ">GH¢ {{total.amountSum.toFixed(3)}}</p>
                </div>
                
            </div> -->

            <div class="col-lg-12 col-md-12 bottom-hr"><hr></div>
            <br>
            <div class="text-right">
                <button type="submit" (click)="openModal(content)" class="btn btn-fill btn-sm modal-btn">Pay Now</button>
            </div>

        </div>
        <div class="row h-100 justify-content-center align-items-center" *ngIf="!item">
            <div class="signup-image">
                <img [src]="'assets/img/paynow/utility.png'" onerror="this.src='assets/img/paynow/church_pay.png'" alt="image">
            </div>
        </div>

        <div class="row h-100 justify-content-center align-items-center" hidden>
            <div class="col-lg-6 col-md-12" *ngIf="list.length > 0" hidden>
                <div class="features-holder-content">
                    <div class="section-title">
                        <!-- <img src="assets/img/paynow/ecg.png" alt="image"> -->
                        <h2>Invoice Details</h2>
                        <div class="bar"></div>
                        <!-- <p *ngFor="list of item.description">Pay for ECG, Ghana Water, DSTV, GOTV, etc.</p> -->
                        <!-- <p>Pay for ECG, Ghana Water, DSTV, GOTV, etc.</p> -->
                        
                        <table *ngIf="list.length > 0" id="invoice-table" class="display"  style="width:100%">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Unit Price</th>
                                    <th>Quantity</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let l of list">
                                    <td>{{l.name}}</td>
                                    <td>{{l.unit_price}}</td>
                                    <td>{{l.quantity}}</td>
                                    <td>{{l.amount}}</td>
                                </tr>
                            </tbody>
                        </table>    
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12" *ngIf="!item" hidden>
                <div class="signup-image">
                    <img [src]="'assets/img/paynow/utility.png'" onerror="this.src='assets/img/paynow/church_pay.png'" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 "></div>

            <div class="col-lg-6 col-md-12 ">
                <div class="signup-form">

                    <form method="Post" #billForm="ngForm" *ngIf="!item?.code" (ngSubmit)="getItem()">
                        <h3 class="text-center">Provide Invoice Code </h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Item Code" name="code" [(ngModel)]="pay.code" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="billForm.invalid" class="btn btn-primary btn-sm">Verify Invoice Code</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form method="Post" #paymentForm="ngForm" *ngIf="!verify && item?.code" (ngSubmit)="sendOtp()">
                        <h3 class="text-center">Amount {{pay?.amount | currency:'GHC '}}</h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="FullName" name="name" [(ngModel)]="pay.name">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="tel" class="form-control" placeholder="Mobile Number" name="mobile" [(ngModel)]="pay.mobile" required>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="pay.email">
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" class="form-control" placeholder="Quantity" name="quantity" [(ngModel)]="pay.amount" (change)="changeQuantity($event)" min="1" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" class="form-control" placeholder="Mobile number" name="mobile" [(ngModel)]="pay.mobile" required>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <select class="form-control" name="provider" [(ngModel)]="pay.network" required>
                                        <option value="" selected hidden>Select Network</option>
                                        <option value="MTN">MTN</option>
                                        <option value="Vodafone">Vadafone</option>
                                        <option value="AirtelTigo">AirtelTigo</option>
                                    </select>
                                </div>
                                <!-- <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Reference" name="reference" [(ngModel)]="pay.reference" reference>
                                </div> -->
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="paymentForm.invalid" class="btn btn-fill btn-sm">Verify Mobile Number</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form method="Post" #otpForm="ngForm" *ngIf="verify" (ngSubmit)="verifyOtp()">
                        <h3 class="text-center">Verify Otp</h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Otp" name="otp" [(ngModel)]="pay.otp" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="otpForm.invalid" class="btn btn-fill btn-md">MAKE PAYMENT</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- <div class="col-lg-12 col-md-12 separator"></div> -->
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start Signup Area -->
<section class="signup-area ptb-100" hidden>
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="signup-image">
                    <img [src]="item?.image" onerror="this.src='assets/img/paynow/homepage.png'" alt="image">
                </div>
                <!-- <br> -->
                <div class="text-center" *ngIf="item?.code">
                    <h4>{{item?.merchant}}</h4>
                    <!-- <p> {{item?.category}} | {{item?.amount|currency:'GHS '}}  </p> -->
                    <!-- <p> {{item?.description}} </p> -->
                    <!-- <p> {{item.country}} | {{item.region}} | <a target="_blank" href="{{item.website}}">{{item.website}}</a></p> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="signup-form">

                    <form method="Post" #billForm="ngForm" *ngIf="!item?.code" (ngSubmit)="getItem()">
                        <h3 class="text-center">Provide Invoice Code </h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Item Code" name="code" [(ngModel)]="pay.code" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="billForm.invalid" class="btn btn-primary btn-sm">Verify Item Code</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form method="Post" #paymentForm="ngForm" *ngIf="!verify && item?.code" (ngSubmit)="sendOtp()">
                        <h3 class="text-center">Amount {{pay?.amount | currency:'GHC '}}</h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="tel" class="form-control" placeholder="Mobile Number" name="name" [(ngModel)]="pay.mobile" required>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="pay.email">
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" class="form-control" placeholder="Quantity" name="quantity" [(ngModel)]="pay.amount" (change)="changeQuantity($event)" min="1" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" class="form-control" placeholder="Mobile number" name="mobile" [(ngModel)]="pay.mobile" required>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <select class="form-control" name="provider" [(ngModel)]="pay.provider" required>
                                        <option value="" selected hidden>Select Network</option>
                                        <option value="MTN">MTN</option>
                                        <option value="Vodafone">Vadafone</option>
                                        <option value="AirtelTigo">AirtelTigo</option>
                                    </select>
                                </div>
                                <!-- <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Reference" name="reference" [(ngModel)]="pay.reference" reference>
                                </div> -->
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="paymentForm.invalid" class="btn btn-primary btn-sm">Verify Mobile Number</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form method="Post" #otpForm="ngForm" *ngIf="verify" (ngSubmit)="verifyOtp()">
                        <h3 class="text-center">Verify Otp</h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Otp" name="otp" [(ngModel)]="pay.otp" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="otpForm.invalid" class="btn btn-primary btn-lg">MAKE PAYMENT</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Signup Area -->
