<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>About Us</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Our process is simple; Our products are powerful.</h2>
                        <div class="bar"></div>
                        <p>We belief that having access to financial services creates opportunity. PayNow is committed to democratizing payment services and empowering people and businesses to join and thrive in the global economy.</p>
                    </div>
                    <!-- <p>We belief that having access to financial services creates opportunity. PayNow is committed to democratizing payment services and empowering people and businesses to join and thrive in the global economy.</p> -->

                    <p>Our interoperability platform enables payment of services across multiple channels. This gives our end users the confidence to connect and transact in new and powerful ways, whether they are online, on a short code , in an app, or
                        in person. Through strategic partnerships, PayNow creates better ways to move funds, and offers choice and flexibility when sending payments, paying or getting paid. </p>

                    <p>Our services range from payment to Churches, Insurance, Utilities, Mobile Airtime, Merchants, Collections, Bills and also booking for medical services.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/paynow/about-us.png" alt="image">
            </div>
        </div>
    </div>

    <div class="about-inner-area">
        <div class="container">
            <div class="row">

                <div class="col-lg-3 col-md-6" hidden>
                    <div class="about-text">
                        <h3>Why Use PayNow</h3>
                        <p>We provide multiple payment access channels to the convenience of our clients and customers.</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-text">
                        <h3>Our Channels</h3>
                        <p>PayNow offers multiple channels to access our secured payment platform. Our consumers are not limited to any geographical location, access to internet or type of mobile device. Transactions can be performed by anyone anywhere.</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-text">
                        <h3>USSD *789*0#</h3>
                        <p>No need to rent or manage a USSD short code to receive payments. PayNow offers USSD as a service to our clients to receive payments from their customers on all networks. We provide online and offline solutions of mobile money wallets and bank accounts over USSD. Our latest add on is a Direct Debit service which prompt end users to confirm a transaction by entering their mobile money PIN.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6" hidden>
                    <div class="about-text">
                        <h3>WEBPay</h3>
                        <p>WEBPay is another alternative payment channel PayNow offers to small business owners. </p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6" hidden>
                    <div class="about-text">
                        <h3>Chat bot</h3>
                        <p>Activating conversational chat bot into your existing sales channels can help you drive better engagement with customers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-inner-area">
        <div class="container">
            <div class="row mt-30">

                <div class="col-lg-6 col-md-12">
                    <div class="about-text">
                        <h3>WEBPay</h3>
                        <p>WEBPay is another alternative payment channel PayNow offers to small business owners. Receiving card payment online becomes a reality with our WebPay channel. All transactions are real time ,secured and prompt.</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-text">
                        <h3>Chat bot</h3>
                        <p>Activating conversational chat bot into your existing sales channels can help you drive better engagement with customers. With artificial intelligence a chat bot can anticipate a customer’s need and provide curated recommendations that align with each customer’s profile.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100 bg-gray" hidden>
    <div class="container">
        <div class="section-title">
            <h2>Our Smart Team</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row">
            <div class="team-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="single-team-member">
                        <img src="assets/img/team-img1.jpg" alt="team">

                        <div class="team-content">
                            <h3>John Doe</h3>
                            <span>CEO</span>

                            <ul>
                                <li><a href="#"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-team-member">
                        <img src="assets/img/team-img2.jpg" alt="team">

                        <div class="team-content">
                            <h3>Steven Smith</h3>
                            <span>Developer</span>

                            <ul>
                                <li><a href="#"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-team-member">
                        <img src="assets/img/team-img3.jpg" alt="team">

                        <div class="team-content">
                            <h3>David Luiz</h3>
                            <span>Designer</span>

                            <ul>
                                <li><a href="#"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-team-member">
                        <img src="assets/img/team-img4.jpg" alt="team">

                        <div class="team-content">
                            <h3>Park Tim</h3>
                            <span>SEO Expert</span>

                            <ul>
                                <li><a href="#"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-team-member">
                        <img src="assets/img/team-img5.jpg" alt="team">

                        <div class="team-content">
                            <h3>Doglas Costa</h3>
                            <span>Manager</span>

                            <ul>
                                <li><a href="#"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Want to received payment from your customers easily? You're in the right place!</p>
            </div>

            <div class="col-lg-5 col-md-5 text-right">
                <a [routerLink]="['/contact_us']" class="btn btn-primary">Talk to Us</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray" hidden>
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="feedback-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client1.jpg" alt="client">
                            <h3>Alit John</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client2.jpg" alt="client">
                            <h3>Alit John</h3>
                            <span>Jhon Smith</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client3.jpg" alt="client">
                            <h3>Steven John</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client1.jpg" alt="client">
                            <h3>David Warner</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<!-- <app-partner></app-partner> -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Receive Payments On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">

                    <h3>Mail</h3>
                    <span>Send Mail</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/slack.png" alt="facebook">

                    <h3>Facebook</h3>
                    <span>Messager</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/twitter.png" alt="mailchimp">

                    <h3>Twitter</h3>
                    <span>Payment Links</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/instagram.png" alt="mailchimp">

                    <h3>Instagram</h3>
                    <span>Payment Link</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->