import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  log: any;
  constructor(private titleService: Title, private api: ApiService) {
      this.titleService.setTitle('PayNow - Login Page');
  }

  ngOnInit() {
    this.resetLogin();
  }

  login() {
    this.api.post('auth',this.log).subscribe((data: any) => {
      localStorage.setItem('Creed', JSON.stringify(data.access_token));
      localStorage.setItem('Date', JSON.stringify(data.date));
      // this.msg.success('You have been Successfully logedin to CREED', 'Login');
      window.location.replace('https://portal.paynowafrica.com/login?token='+data.access_token+'&date='+data.date+'message=login successful');
      // this.router.navigate([this.returnUrl]); location.reload(true);
    }, (err: HttpErrorResponse) => {
      // this.isLoginError = true; this.res = 'Incorrect Username or Password';
      // this.msg.error('Unable to login : ' + err, 'Login UnSuccessfull');
    });
  }

  resetLogin(form?: NgForm) {
    if (form != null) { form.reset(); }
    this.log = { username: '', password: '' };
  }
}
