import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor() { }

  error(err) {
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: err || 'Internal Server Error',
      showConfirmButton: true,
      // timer: 3000
    })
  }

  notify(err) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: err || 'Internal Server Error',
      showConfirmButton: true,
      // timer: 3000
    })
  }

  success(data) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: data.title || '',
      text: data.message || 'Your work has been saved',
      showConfirmButton: false,
      timer: 3500
    })
  }
}
