<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12" *ngIf="!item?.code">
                <h2>Provide a Valid Item Code</h2>
                <br>
                <h3 class="text-white">Receive payments automatically in your corporate bank account within 24 hours from your customers.</h3>
            </div>
            <div class="col-lg-12" *ngIf="item?.code">
                <h2>{{item.code}} - {{item.name}}</h2>
                <br>
                <h3 class="text-white">{{item?.description}}</h3>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Signup Area -->
<section class="signup-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="signup-image">
                    <img [src]="item?.image" onerror="this.src='assets/img/paynow/paynow.png'" alt="image">
                </div>
                <!-- <br> -->
                <div class="text-center" *ngIf="item?.code">
                    <h4>{{item?.merchant}}</h4>
                    <p> {{item?.category}} | {{item?.amount|currency:'GHS '}}  | <b>Available Quantity {{item?.quantity}}</b> </p>
                    <!-- <p> {{item?.description}} </p> -->
                    <!-- <p> {{item.country}} | {{item.region}} | <a target="_blank" href="{{item.website}}">{{item.website}}</a></p> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="signup-form">

                    <form method="Post" #itemForm="ngForm" *ngIf="!item?.code" (ngSubmit)="getItem()">
                        <h3 class="text-center">Provide Item Code </h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Item Code" name="code" [(ngModel)]="pay.code" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="itemForm.invalid" class="btn btn-primary btn-sm">Verify Item Code</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form method="Post" #paymentForm="ngForm" *ngIf="!verify && item?.code" (ngSubmit)="sendOtp()">
                        <h3 class="text-center">Amount {{pay?.amount | currency:'GHC '}}</h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name" name="name" [(ngModel)]="pay.name" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="pay.email">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" class="form-control" placeholder="Quantity" name="quantity" [(ngModel)]="pay.quantity" (change)="changeQuantity($event)" min="1" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" class="form-control" placeholder="Mobile number" name="mobile" [(ngModel)]="pay.mobile" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <select class="form-control" name="provider" [(ngModel)]="pay.provider" required>
                                        <option value="" selected hidden>Select Network</option>
                                        <option value="MTN">MTN</option>
                                        <option value="Vodafone">Vadafone</option>
                                        <option value="AirtelTigo">AirtelTigo</option>
                                    </select>
                                </div>
                            <!-- </div>
                            <div class="col-lg-12"> -->
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Address" name="address" [(ngModel)]="pay.address" reference>
                                </div>
                                <!-- <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Reference" name="reference" [(ngModel)]="pay.reference" reference>
                                </div> -->
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="paymentForm.invalid" class="btn btn-primary btn-sm">Verify Mobile Number</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form method="Post" #otpForm="ngForm" *ngIf="verify" (ngSubmit)="verifyOtp()">
                        <h3 class="text-center">Verify Otp</h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Otp" name="otp" [(ngModel)]="pay.otp" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="otpForm.invalid" class="btn btn-primary btn-lg">MAKE PAYMENT</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Signup Area -->
