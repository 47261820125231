<!-- Start Payment Processing Main Banner -->
<div class="main-banner payment-processing-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <h1>Receive Payment with PayNow</h1>
                            <p>No borders, no barriers  accept payments anytime anywhere including localized payment options - such as mobile money.</p>

                            <a [routerLink]="['/signup']" routerLinkActive="active" class="btn btn-primary">Get Started</a>
                            <!-- <a href="https://web.facebook.com/paynowafrica/videos/392274918158979" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a> -->
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="overview-img">
                            <img src="assets/img/paynow/homepage.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="bg">
                        </div>
                        <!-- <div class="paymant-processing-image">
                            <img src="assets/img/payment-process-shape/bg.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="bg">
                            <img src="assets/img/payment-process-shape/card.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="card">
                            <img src="assets/img/payment-process-shape/cursor.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="cursor">
                            <img src="assets/img/payment-process-shape/dollar1.png" class="wow zoomIn" data-wow-delay="0.6s" alt="dollar1">
                            <img src="assets/img/payment-process-shape/dollar2.png" class="wow bounceIn" data-wow-delay="0.6s" alt="dollar2">
                            <img src="assets/img/payment-process-shape/laptop.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="laptop">
                            <img src="assets/img/payment-process-shape/main-bg.png" class="wow zoomIn" data-wow-delay="0.6s" alt="main-bg">
                            <img src="assets/img/payment-process-shape/number.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="number">
                            <img src="assets/img/payment-process-shape/paper.png" class="wow rollIn" data-wow-delay="0.6s" alt="paper">
                            <img src="assets/img/payment-process-shape/main-pic.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/bg3.png" alt="bg"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

    <!-- Bubble Animte -->
    <div class="bubble-animate">
        <div class="circle small square1"></div>
        <div class="circle small square2"></div>
        <div class="circle small square3"></div>
        <div class="circle small square4"></div>
        <div class="circle small square5"></div>
        <div class="circle medium square1"></div>
        <div class="circle medium square2"></div>
        <div class="circle medium square3"></div>
        <div class="circle medium square4"></div>
        <div class="circle medium square5"></div>
        <div class="circle large square1"></div>
        <div class="circle large square2"></div>
        <div class="circle large square3"></div>
        <div class="circle large square4"></div>
    </div>
</div>
<!-- End Payment Processing Main Banner -->

<!-- Start Boxes Area -->
<section class="boxes-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-comment"></i>
                    </div>
                    <h3>Why Use PayNow</h3>
                    <p>We provide multiple payment access channels to the convenience of our clients and customers with  tailor-made payment solutions to business of all sizes.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-like"></i>
                    </div>
                    <h3>How it Works</h3>
                    <p>Register for a merchant account  online in minutes and use our channels and to securely accept payments. We’ll deposit your funds directly into your bank account..</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-laptop"></i>
                    </div>
                    <h3>Get Started</h3>
                    <p>Get up and running with PayNow in as little as 10 minutes. A seamless activation after a  successful  registration. PayNow then active merchant account to start receiving payment.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Boxes Area -->

<!-- Start Overview Area -->
<section class="overview-section ptb-100" hidden>
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/registration.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-power"></i>
                    </div>
                    <h3>How to Register</h3>
                    <p>We are glad to have you on board. kindly make the following information ready and let’s start the registration process asap</p>
                    <ul>
                        <li>Business Name</li>
                        <li>Tax Identification Number</li>
                        <li>Contact Number</li>
                        <li>Email Address</li>
                    </ul>
                    <a [routerLink]="['/signup']" class="btn btn-primary">Register</a>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-share-alt"></i>
                    </div>
                    <h3>Start Receiving Payment</h3>
                    <p>From ecommerce stores, to subscription businesses, to platforms and marketplaces, we offer a complete stack for all your payments needs across channels.</p>

                    <ul>
                        <li>Share Payment Link to receive payment. </li>
                        <li>Share e Invoice to receive payment.</li>
                        <li>Share a product code to receive payment for an Item</li>
                        <li>Receive payment from Web CheckOut.</li>
                        <li>Send bulk payments multiple beneficiaries</li>
                        <li>Send bulk Sms to multiple recipients.</li>
                        <!-- <li>Share a product code to receive payment for an Item</li> -->
                    </ul>
                    <!-- <a href="#" class="btn btn-primary">Read More</a> -->
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/receive-payment.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/paynow.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>Pay Merchant</h3>
                    <p>Join the winning team ! Become a Paynow merchant today and start receiving payment from anywhere, see the transactions live as it happens and get settlement in to your bank account.</p>

                    <ul>
                        <li>Recieve Payment From Ussd</li>
                        <li>Payment Link</li>
                        <li>Invoice</li>
                        <li>Pay 4 Item</li>
                    </ul>
                    <!-- <a href="#" class="btn btn-primary">PayNow</a> -->
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>Pay Church</h3>
                    <p>Pay Church is a payment service designed for church members to make payments or donate funds to their respective churches at their convenience. The Pay Church can be access through any of the PayNow payment channels for example USSD, WEBPay and Chat bot. All payments are first initiated by church members and also approve with PIN and OTP to confirm. Payments are funded from mobile money wallets and cards.</p>

                    <ul>
                        <!-- <li>Unimited Video Call</li> -->
                        <!-- <li>Add Favourite contact</li> -->
                        <!-- <li>Camera Filter</li> -->
                    </ul>
                    <!-- <a href="#" class="btn btn-info">PayNow</a> -->
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/church_pay.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/utility.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-ui-messaging"></i>
                    </div>
                    <h3>Pay Utilities</h3>
                    <p> All payments are first initiated by church members and also approve with PIN and OTP to confirm. Payments are funded from mobile money wallets and cards.</p>

                    <ul>
                        <li>Pay ECG</li>
                        <li>Pay GWC</li>
                        <li>Pay GOTV/DSTV</li>
                        <li>Buy Airtime/Data Bundle</li>
                    </ul>
                    <!-- <a href="#" class="btn btn-primary">PayNow</a> -->
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <div class="icon">
                        <i class="icofont-users-social"></i>
                    </div>
                    <h3>Pay Group</h3>
                    <p>Groups and Clubs contributions has never been easier or simpler. Imagine soliciting funds for a community project or for an alumni project, your best bet crowd funding platform for social media is with PayNow. We can make this possible via a USSD short code on all networks or by chat bot on Facebook or Instagram. (Ask Us How).</p>

                    <ul>
                        <!-- <li>Unimited Video Call</li> -->
                        <!-- <li>Add Favourite contact</li> -->
                        <!-- <li>Camera Filter</li> -->
                    </ul>
                    <!-- <a href="#" class="btn btn-info">PayNow</a> -->
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/group_save.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/registration.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>SignUp</span> -->
                    <h3>How to Register</h3>
                    <p>We are glad to have you on board. kindly make the following information ready and let’s start the registration process asap.</p>

                    <ul class="features-list">
                        <li>Business Name</li>
                        <li>Tax Identification Number</li>
                        <li>Contact Number</li>
                        <li>Email Address</li>
                    </ul>
                    <br>
                    <a [routerLink]="['/signup']" class="btn btn-primary">Register</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100 bg-gray">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>Notification Alerts</span> -->
                    <h3>Start Receiving Payment</h3>
                    <p>From ecommerce stores, to subscription businesses, to platforms and marketplaces, we offer a complete stack for all your payments needs across channels..</p>

                    <ul class="features-list">
                        <li>Share Payment Link to receive payment. </li>
                        <li>Share e Invoice to receive payment.</li>
                        <li>Receive payment from Web CheckOut.</li>
                        <li>Send bulk payments multiple beneficiaries</li>
                        <li>Send bulk Sms to multiple recipients.</li>
                        <li>Share a product code to receive payment for an Item</li>
                    </ul>
                    <!-- <a [routerLink]="['/signup']" class="btn btn-primary">Register</a> -->
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/receive-payment.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/paynow.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>New Browser</span> -->
                    <h3>Pay Store</h3>
                    <p>Join the winning team ! Become a Paynow merchant today and start receiving payment from anywhere, see the transactions live as it happens and get settlement in to your bank account.</p>

                    <ul class="features-list">
                        <li>Payment Link</li>
                        <li>Invoice</li>
                        <li>Pay Store</li>
                        <li>Recieve Payment</li>
                    </ul>
                    <br>
                    <a [routerLink]="['/payment/pay-merchant']" class="btn btn-primary btn-sm">Pay Store</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100 bg-gray">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>Lightweight</span> -->
                    <h3>Pay Church.</h3>
                    <p>Pay Church is a payment service designed for church members to make payments or donate funds to their respective churches at their convenience. The Pay Church can be access through any of the PayNow payment channels for example USSD, WEBPay and Chat bot. All payments are first initiated by church members and also approve with PIN and OTP to confirm. Payments are funded from mobile money wallets and cards.</p>

                    <!-- <ul class="features-list">
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                    </ul> -->
                    <br>
                    <a [routerLink]="['/payment/pay-church']" class="btn btn-sm btn-primary">Pay Church</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/church_pay.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/ecg.png" alt="image">
                </div>

                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>New Browser</span> -->
                    <h3>Pay Utilities</h3>
                    <p>To pay your utility bill online, with PayNow you will need the meter or number from the payment bill. For a step-by-step guide to using our online payment system at your convenience anywhere anytime.</p>

                    <ul class="features-list">
                        <li>Pay ECG</li>
                        <li>Pay Ghana Water</li>
                        <li>Pay GOTV/DSTV</li>
                        <li>Buy Airtime</li>
                    </ul>
                    <br>
                    <!-- <a [routerLink]="['/payment']" class="btn btn-primary">PayNow</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Overview Area -->
<section class="overview-section software-overview ptb-100 bg-gray">
    <div class="container">
        <div class="overview-box">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 overview-content">
                    <!-- <span>Lightweight</span> -->
                    <h3>Pay Group.</h3>
                    <p>Groups and Clubs contributions has never been easier or simpler. Imagine soliciting funds for a community project or for an alumni project, your best bet crowd funding platform for social media is with PayNow. We can make this possible via a USSD short code on all networks or by chat bot on Facebook or Instagram. (Ask Us How).</p>

                    <!-- <ul class="features-list">
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                        <li>User Friendly</li>
                        <li>High Security</li>
                        <li>Free Updates</li>
                        <li>QR Code Scaner</li>
                    </ul> -->
                    <br>
                    <a [routerLink]="['/payment/pay-group']" class="btn btn-primary btn-sm">Pay Group</a>
                </div>

                <div class="col-lg-6 col-md-6 overview-img">
                    <img src="assets/img/paynow/group_save.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Overview Area -->

<!-- Start Work Process Area -->
<section class="saas-work-process ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>How it work?</h2>
            <div class="bar"></div>
            <p>We belief that having access to financial services creates opportunity. PayNow is committed to democratizing payment services and empowering businesses to join and thrive in the global economy.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-unlock"></i>
                    </div>
                    <h3>Sign up Today</h3>
                    <!-- <p>Morbi pharetra sapien ut mattis.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-like"></i>
                    </div>
                    <h3>Get activated within 72hrs</h3>
                    <!-- <p>Morbi pharetra sapien ut mattis.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-money"></i>
                    </div>
                    <h3>Start receiving payment</h3>
                    <!-- <p>Morbi pharetra sapien ut mattis.</p> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Work Process Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100 bg-gray" hidden>
    <div class="container">
        <div class="section-title">
            <h2>Features Of Paynow</h2>
            <div class="bar"></div>
            <p>We provide multiple payment access channels to the convenience of our clients and customers. Integrated with aggregators and financial service providers, PayNow we provide tailor-made payment solutions to end users.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pay"></i>
                    </div>
                    <h3>Pay Church</h3>
                    <p>Pay Church is a payment service designed for church members to make payments or donate funds to their respective churches at their convenience. The Pay Church can be access through any of the PayNow payment channels for example USSD, WEBPay and Chat bot. All payments are first initiated by church members and also approve with PIN and OTP to confirm. Payments are funded from mobile money wallets and cards</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-music-note"></i>
                    </div>
                    <h3>Pay 4 item</h3>
                    <p>If you own a small business or social media store then Pay 4 Item is the services you need to engage your customers for payment. Pay 4 item has a mini inventory module which enables small business owners can create and assign payment codes for their goods and services online. Pay 4 item accepts payments for goods and services online via all the PayNow channels. (Ask Us How)</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-optic"></i>
                    </div>
                    <h3>Pay Merchant </h3>
                    <p>Merchant ability to receive instant payment from mobile money wallets and cards are made possible with Pay Merchants. (tell me more)</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>Send eBill / Invoice</h3>
                    <p>PayNow merchants send electronic invoices to customers via email or sms in the form of a link. Merchants can see status of invoices at real time. </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-deviantart"></i>
                    </div>
                    <h3>Pay Group / Club</h3>
                    <p>Groups and Clubs contributions has never been easier or simpler. Imagine soliciting funds for a community project or for an alumni project, your best bet crowd funding platform for social media is with PayNow. We can make this possible via a USSD short code on all networks or by chat bot on Facebook or Instagram. (Ask Us How).</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-services">
                    <div class="icon">
                        <i class="icofont-chart-pie-alt"></i>
                    </div>
                    <h3>Send Sms Alert</h3>
                    <p>Paynow Make it possible to you to build your customers database so you can send them notifications via sms for new offers or deals.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->


<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Receive Payments On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">

                    <h3>Mail</h3>
                    <span>Send Mail</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/slack.png" alt="mailchimp">

                    <h3>Facebook</h3>
                    <span>Messager</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/twitter.png" alt="mailchimp">

                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/instagram.png" alt="mailchimp">

                    <h3>Instagram</h3>
                    <span>News Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->

<!-- Start CTA Area -->
<div class="cta-area">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-7">
                <p>Do more on the web, with a fast and secure browser!</p>
            </div>

            <div class="col-lg-5 col-md-5 text-right">
                <a href="contact_us" class="btn btn-primary">Sign Up Today</a>
            </div>
        </div>
    </div>
</div>
<!-- End CTA Area -->

<!-- Start Features Area -->
<section class="features-area payment-processing-features ptb-100" hidden>
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="features-holder-content">
                    <div class="section-title">
                        <h2>Our Products</h2>
                        <div class="bar"></div>
                        <p>We belief that having access to financial services creates opportunity. PayNow is committed to democratizing payment services and empowering people and businesses to join and thrive in the global economy.</p>
                    </div>

                    <p>PayNow Services offers convenience in paying for goods and services on your phone or from Facebook using your mobile wallet. Our services range from payment to Churches, Insurance, Utilities, Mobile Airtime, Merchants, Bills and also booking for medical services.</p>
                    <!-- <a [routerLink]="['/services']" routerLinkActive="active" class="btn btn-primary">Our Services</a> -->
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-holder-box">
                            <img src="assets/img/paynow/church_pay.png" alt="image">
                            <h3>Pay Church</h3>
                            <!-- <p>Church members to make payments or donate funds to their respective churches.</p> -->
                            <a [routerLink]="['/payment/pay_church']" class="btn btn-sm btn-success"></a>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/paynow/insurance.png" alt="image">
                            <h3>Buy Insurance</h3>
                            <!-- <p>Engage your customers for payment. </p> -->
                            <a href="https://saham.paynowafrica.com" target="_blank" class="btn btn-sm btn-success"></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div [routerLink]="['/payment/airtime']" class="single-holder-box mt-50">
                            <img src="assets/img/paynow/airtime.png" alt="image">
                            <h3>Buy Airtime</h3>
                            <!-- <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p> -->
                            <a [routerLink]="['/payment/airtime']" class="btn btn-sm btn-info"></a>
                        </div>

                        <div class="single-holder-box mt-30">
                            <img src="assets/img/paynow/ecg.png" alt="image">
                            <h3>Pay Bill</h3>
                            <!-- <p>Duis hendrerit eget dolor non lora pulvinar. In conse.</p> -->
                            <a [routerLink]="['/payment/utility']" class="btn btn-sm btn-info"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- <app-partner></app-partner> -->