import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  reg: any;
  constructor(private titleService: Title, private api: ApiService, private notify: NotificationsService) {
      this.titleService.setTitle('PayNow Africa - Signup');
  }

  ngOnInit(): void {
    this.init();
  }

  postSignup() {
    // this.reg.to = 'harmonizerblinks@gmail.com';
    this.reg.subject =  'Paynow - SignUp Form';
    this.reg.body = `<h1>Hello,</h1><h2>below are the Contact details: </h2><p>Name: ${this.reg.name}<br>Email: ${this.reg.email}<br>Phone: ${this.reg.mobile}<br>Tax Identification Number: ${this.reg.tin}</p>`;
    this.api.postmail('contact',this.reg).subscribe((data: any) => {
      console.info(data);
      this.notify.success({title: 'Registration Details Received you will be contacted shortly'});
      this.init();
    });
  }

  init() {
    this.reg = { website: 'PayNow', name: '', mobile: '', email: '', tin: '', to: 'info@paynowafrica.com, sales@paynowafrica.com, gd@alias-solutions.net, harmonizerblinks@gmail.com', subject: '', location: '', text: '', body: `<h1>Hello,</h1><h2>below are the details: </h2><p>Name:  $lname<br>Email: $email<br>Phone: $ctype<br>Gift Selected: $gtype<br>Address: $address<br>ID: $id<br></p>` };
  }
}
