import { Injectable, Injector } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/';
// import 'rxjs/add/observable/of';
// Cool library to deal with errors: https://www.stacktracejs.com
// import * as StackTraceParser from 'error-stack-parser';
// import { SigninService } from './signin.service';
// import { FirebaseService } from './firebase.service';
// import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  active: any; data: Observable<any[]>;
  // tslint:disable-next-line:max-line-length
  constructor(private injector: Injector) {  }

  log(error) {
    // Log the error to the console
    // Send error to server
    // const errorToSend = this.addContextInfo(error);
    // this.addError(errorToSend);
    // this._api.post('Errors', errorToSend);
  }

  request(reqs: HttpRequest<any>) {
    // Log the request to the console
    // console.log(reqs);
    // Send error to server
    // const req = this.addContext(reqs);
    // console.log(req);
    // this.addRequest(req);
    // this._api.post('Requests', req);
  }

  addContextInfo(error) {
    // All the context details that you want (usually coming from other services; Constants, UserService...)
    // const active = this.use.userDetails();
    const name = error.name || null;
    const appId = this.active.id;
    const branch = this.active.branchid;
    const user = this.active.unique_name;
    const time = new Date();
    const id = `${appId}-${user}-${time.getTime()}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = JSON.stringify(error.message) || JSON.stringify(error);
    const errors = JSON.stringify(error);
    // const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
    const errorToSend = { id, branch, name, appId, user, time, url, status, message };
    return errorToSend;
  }

  addContext(msg: HttpRequest<any>) {
    // const header = msg.headers || null;
    const appId = this.active.id;
    const branch = this.active.branchid;
    const user = this.active.unique_name;
    const time = new Date();
    const id = `${appId}-${user}-${time.getTime()}`;
    // const location = this.injector.get(LocationStrategy);
    const url = msg.url;
    const method = msg.method;
    const body = msg.body || null;
    const type = msg.responseType;
    const progress = msg.reportProgress;
    const request = JSON.stringify(msg);
    const data = { id, branch, name, appId, user, time, url, progress, method, type, body, request };
    return data;
  }

  addError(value) {
    // this.db.collection('Errors').add(value);
  }

  addRequest(value) {
    // this.db.collection('Requests').add(value);
  }

  // getError(): Observable<any[]> {
  //   return this.db.collection('/Errors').snapshotChanges();
  // }

}


