<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Buy Airtime</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Signup Area -->
<section class="signup-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="signup-image">
                    <img src="assets/img/paynow/airtime.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="signup-form">
                    <h3>Buy Airtime</h3>
                     <form method="Post" #buyairtimeForm="ngForm" (ngSubmit)="postPayment()">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name" name="name" [(ngModel)]="pay.userid" required>
                                </div>
                                <!-- <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="pay.email">
                                </div> -->
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" class="form-control" placeholder="Mobile Money number" name="payermobilenumber" [(ngModel)]="pay.payermobilenumber" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" name="payeroperatorname" [(ngModel)]="pay.payeroperatorname" required>
                                                <option value="" selected>Network</option>
                                                <option value="MTN">MTN</option>
                                                <option value="Vodafone">Vadafone</option>
                                                <option value="AirtelTigo">AirtelTigo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="number" class="form-control" placeholder="Amount" name="amount" [(ngModel)]="pay.amount" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="checkme" name="buyer" [(ngModel)]="buyer">
                                    <label class="form-check-label" for="checkme">Buying for a Different Mobile Number</label>
                                </div>
                            </div>
                            <div class="col-lg-12" *ngIf="buyer">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" class="form-control" placeholder="Receiving Mobile number" name="recipientmobilenumber" [(ngModel)]="pay.recipientmobilenumber" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <select class="form-control" name="recipient_mobile_network" [(ngModel)]="pay.recipient_mobile_network" required>
                                                <option value="" selected>Network</option>
                                                <option value="MTN">MTN</option>
                                                <option value="Vodafone">Vadafone</option>
                                                <option value="AirtelTigo">AirtelTigo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="buyairtimeForm.invalid" class="btn btn-primary btn-lg">Buy Airtime</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Signup Area -->
