import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SeoService } from 'src/app/services/seo.service';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pay-bill',
  templateUrl: './pay-bill.component.html',
  styleUrls: ['./pay-bill.component.scss']
})
export class PayBillComponent implements OnInit {
  item: any = { code: '', amount: 0 }; 
  pay: any; 
  url = environment; 
  verify: boolean = false; 
  otp: any; 
  list: any[] = [];
  total_amount: any[] = [];
  taxPercent: any[] = [];
  subTaxSum: any; 
  vat: any;
  
  total: any = { amountSum: 0, taxSum: 0, totalSum: 0};
  // private modalService: NgbModal
  constructor(private modalService: NgbModal, private titleService: Title, public route: ActivatedRoute, private router: Router, public api: ApiService, private seo: SeoService, private notify: NotificationsService, ) { 
    this.item = this.route.snapshot.data.bill; this.init();
    console.log(this.item);
    if(this.item) {
      this.pay.amount = this.item.amount;
      this.list = JSON.parse(this.item.description); console.info(this.list);
      this.titleService.setTitle('Payment - ' + this.item.name);
      this.seo.generateTags({
        title: 'Pay Invoice - '+ this.item.name,
        description: 'Read about Paynow Africa',
        image: this.url.imageUrl + this.item.logo || this.url.app + 'assets/img/paynow/reeive-payment.png'
      });
    } //  else { this.router.navigate(['/404']); }
  }

  ngOnInit(): void {
    this.sumAmount();
  }

  getItem() {
    this.router.navigate(['payment/pay-bill', this.pay.code.toLowerCase()]);
  }

  changeQuantity(event) {
    if(this.pay.quantity > 0) {
      this.pay.amount = this.item.amount * this.pay.quantity;
    }
  }

  sendOtp() {
    this.pay.code = this.item.code;
    this.pay.reference = this.item.code;
    const url = 'website/otp/'+this.pay.mobile+'/Pay-Invoive?code=' +this.item.code;
    this.api.get(url).subscribe((data: any) => {
      console.info(data); this.otp = data; this.verify = true;
      this.notify.success({message: 'Request Sent, Kindly Confirm Otp Send to your phone' });
    });
  }

  verifyOtp() {
    const url = 'website/otp/verify/'+this.pay.mobile+'/'+this.pay.otp+'?code=' +this.item.code;
    this.api.get(url).subscribe((data: any) => {
      console.info(data); this.postPayment();
    });
  }

  postPayment() {
    this.pay.code = this.item.code;
    this.pay.reference = this.item.code;
    this.api.post('website/payinvoice',this.pay).subscribe((data: any) => {
      console.info(data);
      this.notify.success({title: 'success', message: 'Request Sent, Kindly Confirm request from your phone' });
      this.init();
      this.router.navigate(['payment/pay-bill']);
    });
  }

  sumAmount(){
    this.total_amount = this.list.map(t=>t.amount);
    console.log(this.total_amount);
    // console.log(this.total_amount.length);
    this.total.amountSum = this.total_amount.reduce((a, b)=> { return a + b; }, 0);
    console.log(this.total.amountSum);

    this.subTaxSum = (this.total.amountSum * 0.025) * 2;
    this.vat = (this.subTaxSum + this.total.amountSum) * 0.125;
    this.total.taxSum = this.subTaxSum + this.vat;
    this.total.totalSum = (this.subTaxSum + this.total.amountSum) + this.vat;
    console.log(this.total.totalSum);
  }

  openModal(content: any) {
    this.modalService.open(content);
  }

  init() {
    this.pay = { code: '', type: 'Invoice', quantity: 1, name: '', email: '', source: 'Payment Link', mobile: '', network: '', amount:0, currency: 'GH¢', service: 'Pay-Invoive', otp: '', reference: '', address: '', date: new Date };
    this.verify = false;
  }

}
