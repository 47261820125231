import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SeoService } from 'src/app/services/seo.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.scss']
})
export class UtilityComponent implements OnInit {
  item: any = { code: '', amount: 0 }; pay: any; url = environment; verify: boolean = false; otp: any; type: string = '';
  constructor(private titleService: Title, public route: ActivatedRoute, private router: Router, public api: ApiService, private seo: SeoService, private notify: NotificationsService) { 
    this.item = this.route.snapshot.data.utility; this.init();
    console.log(this.item);
    this.route.paramMap.subscribe(param=>{
      this.type = param.get('type');
    });
    if(this.item) {
      this.pay.amount = this.item.amount;
      this.titleService.setTitle('Payment - ' + this.item.name);
      this.seo.generateTags({
        title: 'Payment - '+ this.item.name,
        description: 'Read about Paynow Africa',
        image: this.url.imageUrl + this.item.logo || this.url.app + 'assets/img/app/payment-page.png'
      });
    } // else { this.router.navigate(['/404']); }
  }

  ngOnInit(): void {
  }

  getItem() {
    this.router.navigate(['payment/pay-utility', this.pay.code.toLowerCase()]);
  }

  changeAmount(event) {
    if(this.pay.amount > 0) {
      this.pay.amount = this.item.amount * this.pay.quantity;
    }
  }

  sendOtp() {
    this.pay.code = this.item.code;
    const url = 'website/otp/'+this.pay.mobile+'/Pay-Merchant?code=' +this.item.code;
    this.api.get(url).subscribe((data: any) => {
      console.info(data); this.otp = data; this.verify = true;
      this.notify.success({message: 'Request Sent, Kindly Confirm Otp Send to your phone' });
    });
  }

  verifyOtp() {
    this.pay.code = this.item.code;
    const url = 'website/otp/verify/'+this.pay.mobile+'/'+this.pay.otp+'?code=' +this.item.code;
    this.api.get(url).subscribe((data: any) => {
      console.info(data); this.postPayment();
    });
  }

  postPayment() {
    this.pay.code = this.item.code;
    this.api.post('paynow/merchant',this.pay).subscribe((data: any) => {
      console.info(data);
      this.notify.success({title: 'success', message: 'Request Sent, Kindly Confirm request from your phone' });
      this.init();
      this.router.navigate(['payment/pay-utility']);
    });
  }

  init() {
    this.pay = { code: '', type: 'Payment', quantity: 1, name: '', email: '', source: 'Payment Link', mobile: '', provider: '', amount:0, currency: 'GH¢', service: 'Pay Utility', otp: '', reference: '', address: '', date: new Date };
    this.verify = false;
  }

}
