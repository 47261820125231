<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12" *ngIf="!church?.code">
                <h2>Provide a Valid Store Code</h2>
                <br>
                <h3 class="text-white">We are happy to process your payment. A complete payments solution to power all scales of growth.</h3>
            </div>
            <div class="col-lg-12" *ngIf="church?.code">
                <h2>{{church.code}} - {{church.name}}</h2>
                <br>
                <h3 class="text-white">Pay for Goods and Services etc.</h3>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Signup Area -->
<section class="signup-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="signup-image">
                    <img [src]="api.imgApi + church?.logo" onerror="this.src='assets/img/paynow/paynow.png'" alt="image">
                </div>
                <!-- <br> -->
                <div class="text-center" *ngIf="church?.code">
                    <!-- <h4>{{church.church}}, {{church.name}}</h4> -->
                    <p> {{church?.mobile}} | {{church?.email}} </p>
                    <p> {{church?.address}} </p>
                    <!-- <p> {{church.country}} | {{church.region}} | <a target="_blank" href="{{church.website}}">{{church.website}}</a></p> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="signup-form">

                    <form method="Post" #merchantForm="ngForm" *ngIf="!church?.code" (ngSubmit)="getMerchant()">
                        <h3 class="text-center">Provide Store Code </h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Merchant Code" name="code" [(ngModel)]="pay.code" required>
                                </div>
                            </div>                            
                            <!-- <div class="col-lg-12 text-left">
                                <p class="forgot-password"><a [routerLink]="['/payment/pay-store']">pay for item</a></p>
                            </div> -->
                            <!-- <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="merchantForm.invalid" class="btn btn-primary btn-sm">Verify Store Code</button>
                                </div>
                            </div> -->
                            <div class="col-lg-12 text-right">
                                <button type="submit" [disabled]="merchantForm.invalid" class="btn btn-primary">Verify Store Code</button>
                                <br> <span>Got Item Code? <a [routerLink]="['/payment/pay-store']">pay for item!</a></span>
                            </div>
                        </div>
                    </form>
                    <form method="Post" #paymentForm="ngForm" *ngIf="!verify && church?.code" (ngSubmit)="sendOtp()">
                        <h3 class="text-center">Make Payment</h3>
                        <div class="row">
                            <!-- <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name" name="name" [(ngModel)]="pay.name" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="pay.email">
                                </div>
                            </div> -->
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" class="form-control" placeholder="Amount" name="amount" [(ngModel)]="pay.amount" required>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="tel" class="form-control" placeholder="Mobile number" name="mobile" [(ngModel)]="pay.mobile" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <select class="form-control" name="network" [(ngModel)]="pay.network" required>
                                        <option value="" selected hidden>Select Network</option>
                                        <option value="MTN">MTN</option>
                                        <option value="Vodafone">Vadafone</option>
                                        <option value="AirtelTigo">AirtelTigo</option>
                                    </select>
                                </div>
                            <!-- </div>
                            <div class="col-lg-12"> -->
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Reference" name="reference" [(ngModel)]="pay.reference" reference>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="paymentForm.invalid" class="btn btn-primary btn-sm">Verify Mobile Number</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form method="Post" #otpForm="ngForm" *ngIf="verify" (ngSubmit)="verifyOtp()">
                        <h3 class="text-center">Verify Otp</h3>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Enter Otp" name="otp" [(ngModel)]="pay.otp" required>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" [disabled]="otpForm.invalid" class="btn btn-primary btn-lg">MAKE PAYMENT</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Signup Area -->
