import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaasLandingComponent } from './views/pages/saas-landing/saas-landing.component';
import { AppLandingComponent } from './views/pages/app-landing/app-landing.component';
import { DigitalMarketingComponent } from './views/pages/digital-marketing/digital-marketing.component';
import { ProductLandingComponent } from './views/pages/product-landing/product-landing.component';
import { SoftwareLandingComponent } from './views/pages/software-landing/software-landing.component';
import { BookLandingComponent } from './views/pages/book-landing/book-landing.component';
import { StartupAgencyComponent } from './views/pages/startup-agency/startup-agency.component';
import { PaymentProcessingComponent } from './views/pages/payment-processing/payment-processing.component';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { FeaturesComponent } from './views/pages/features/features.component';
import { ServicesComponent } from './views/pages/services/services.component';
import { GalleryComponent } from './views/pages/gallery/gallery.component';
import { TeamComponent } from './views/pages/team/team.component';
import { PricingComponent } from './views/pages/pricing/pricing.component';
import { ProjectsComponent } from './views/pages/projects/projects.component';
import { BlogComponent } from './views/pages/blog/blog.component';
import { BlogDetailsComponent } from './views/pages/blog-details/blog-details.component';
import { ContactUsComponent } from './views/pages/contact-us/contact-us.component';
import { FaqComponent } from './views/pages/faq/faq.component';
import { ComingSoonComponent } from './views/pages/coming-soon/coming-soon.component';
import { LoginComponent } from './views/pages/login/login.component';
import { SignupComponent } from './views/pages/signup/signup.component';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';

import { HomeComponent } from './views/pages/home/home.component';
import { PaymentsComponent } from './views/pages/payments/payments.component';
import { PaymentComponent } from './views/pages/payments/payment/payment.component';
import { AirtimeComponent } from './views/pages/payments/utility/airtime/airtime.component';
import { UtilityComponent } from './views/pages/payments/utility/utility.component';
import { PayGroupComponent } from './views/pages/payments/pay-group/pay-group.component';
import { PayItemComponent } from './views/pages/payments/pay-item/pay-item.component';
import { PayBillComponent } from './views/pages/payments/pay-bill/pay-bill.component';
import { SendMomoComponent } from './views/pages/payments/send-momo/send-momo.component';
import { PayChurchComponent } from './views/pages/payments/pay-church/pay-church.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './views/pages/terms-and-conditions/terms-and-conditions.component';


import { Resolver, ItemResolver, InvoiceResolver, MerchantResolver } from './services/resolver.service';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'default', component: SaasLandingComponent },
  { path: 'app-landing', component: AppLandingComponent },
  { path: 'digital-marketing', component: DigitalMarketingComponent },
  { path: 'product-landing', component: ProductLandingComponent },
  { path: 'software-landing', component: SoftwareLandingComponent },
  { path: 'book-landing', component: BookLandingComponent },
  { path: 'startup-agency', component: StartupAgencyComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about_us', component: AboutUsComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'payment', 
    children: [ 
      { path: '', component: PaymentsComponent },
      { path: 'airtime', component: AirtimeComponent },
      { path: 'utility', component: UtilityComponent },
      { path: 'utility/:type', component: UtilityComponent },
      // { path: 'pay_church/:slug', component: PayChurchComponent, resolve: { church: MerchantResolver }, runGuardsAndResolvers: 'always' },
      { path: 'pay-church', 
        children: [ 
          { path: '', component: PayChurchComponent },
          { path: ':slug', component: PayChurchComponent, resolve: { church: MerchantResolver }, runGuardsAndResolvers: 'always' },
        ]
      },
      { path: 'pay-merchant', 
        children: [ 
          { path: '', component: PaymentComponent },
          { path: ':slug', component: PaymentComponent, resolve: { merchant: MerchantResolver }, runGuardsAndResolvers: 'always' },
        ]
      },
      { path: 'pay-utility', 
        children: [ 
          { path: '', component: UtilityComponent },
          { path: 'airtime', component: AirtimeComponent }, 
          { path: 'data', component: AirtimeComponent },  
          { path: ':type', component: UtilityComponent, },
          { path: ':type/:code', component: UtilityComponent, resolve: { utility: MerchantResolver }, runGuardsAndResolvers: 'always' },
        ]
      },
      { path: 'pay-store', 
        children: [
          { path: '', component: PayItemComponent },
          { path: ':slug', component: PayItemComponent, resolve: { item: ItemResolver }, runGuardsAndResolvers: 'always' },
        ]
      },
      { path: 'pay-group', 
        children: [
          { path: '', component: PayGroupComponent },
          { path: ':slug', component: PayGroupComponent, resolve: { group: MerchantResolver }, runGuardsAndResolvers: 'always' },
        ]
      },
      { path: 'pay-bill', 
        children: [
          { path: '', component: PayBillComponent },
          { path: ':slug', component: PayBillComponent, resolve: { bill: InvoiceResolver }, runGuardsAndResolvers: 'always' },
        ]
      }
    ] 
  },
  // { path: 'gallery', component: GalleryComponent },
  // { path: 'team', component: TeamComponent },
  // { path: 'pricing', component: PricingComponent },
  // { path: 'projects', component: ProjectsComponent },
  // { path: 'blog', component: BlogComponent },
  // { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact_us', component: ContactUsComponent },
  { path: 'faqs', component: FaqComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'terms_and_conditions', component: TermsAndConditionsComponent },
  { path: 'privacy_policy', component: PrivacyPolicyComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
