<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <div class="section-title" hidden>
            <h2>Our Partners</h2>
            <div class="bar"></div>
            <p>
                Our partnership enable diverse companies to benefit from our range of services and products. Together we're committed to bridging financial gap one client at a time
            </p>
        </div>
        <div class="row">
            <div class="partner-slides owl-carousel owl-theme">

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partners/partner-continentalcapital.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partners/partner-hmplus.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partners/partner-jislah.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partners/partner-ontime.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partners/partner-sic.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partners/partner-UBUNTU.png" alt="partner"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Partner Area -->
<div class="partner-area" hidden>
    <div class="container">
        <div class="row">
            <div class="partner-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-1.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-2.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-3.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-4.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-5.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-6.png" alt="partner"></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="item">
                        <a href="#"><img src="assets/img/partner-7.png" alt="partner"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->