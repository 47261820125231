<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-footer-widget">
                    <a href="#" class="logo"><img src="assets/img/logo.png" alt="logo2"></a>
                    <p>We are happy to process your payment . A complete payments solution to power all scales of growth.</p>

                    <ul class="social-list">
                        <li><a href="https://facebook.com/paynowafrica" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="https://twitter.com/paynowafrica" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="https://instagram.com/paynowafrica" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="https://linkedin.com/paynowafrica" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Products</h3>

                    <ul class="list">
                        <!-- <li><a [routerLink]="['/payment']" routerLinkActive="active">Payments</a></li> -->
                        <li><a [routerLink]="['/payment/pay-church']" routerLinkActive="active">Pay Church</a></li>
                        <li><a [routerLink]="['/payment/pay-merchant']" routerLinkActive="active">Pay Store</a></li>
                        <li><a [routerLink]="['/payment/pay-group']" routerLinkActive="active">Pay Group</a></li>
                        <li><a [routerLink]="['/payment/pay-store']" routerLinkActive="active">Pay For Item</a></li>
                        <li><a [routerLink]="['/payment/airtime']" routerLinkActive="active">Buy Airtime</a></li>
                        <!-- <li><a [routerLink]="['/payments/bundles']" routerLinkActive="active">Bundles</a></li> -->
                        <!-- <li><a [routerLink]="['/payment/utility']" routerLinkActive="active">Pay Utility</a></li> -->
                        <!-- <li><a [routerLink]="['/tickets']" routerLinkActive="active">Buy Tickets</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6" hidden>
                <div class="single-footer-widget">
                    <h3>Products</h3>

                    <ul class="list">
                        <li><a href="#">USSD</a></li>
                        <li><a href="#">API</a></li>
                        <li><a href="#">Chatbot</a></li>
                        <li><a href="#">Documentation</a></li>
                        <!-- <li><a href="#">Sign Up</a></li> -->
                        <!-- <li><a href="#">Release Notes</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <!-- <li><a [routerLink]="['/services']" routerLinkActive="active">Services</a></li> -->
                        <li><a [routerLink]="['/about_us']" routerLinkActive="active">About</a></li>
                        <li><a [routerLink]="['/contact_us']" routerLinkActive="active">Contact</a></li>
                        <li><a [routerLink]="['/signup']" routerLinkActive="active">Register</a></li>
                        <!-- <li><a [routerLink]="['/faqs']" routerLinkActive="active">FAQ</a></li> -->
                        <li><a href="javascript;" routerLinkActive="active">FAQ</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>&copy; 2020 Paynow Services. All rights reserved Designed by <a href="#">Harmony Alabi</a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a [routerLink]="['/terms_and_conditions']" routerLinkActive="active">Terms & Conditions</a></li>
                        <li><a [routerLink]="['/privacy_policy']" routerLinkActive="active">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->