<!-- Start Book Main Banner -->
<div class="main-banner book-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <span>By: John Smith</span>
                            <h1>Big Mockup Cover Vault</h1>
                            <p>Our passion to work hard and deliver excellent results. It could solve the needs of your customers and develop innovation.</p>

                            <a href="#" class="btn btn-primary">Buy Now</a>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> Watch Video <i class="icofont-play-alt-3"></i></a>
                            <em class="inlucdebooks">* eBook includes iBooks, PDF & ePub versions</em>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="book-image">
                            <img src="assets/img/book-img.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="creative-shape"><img src="assets/img/bg3.png" alt="bg"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</div>
<!-- End Book Main Banner -->

<app-partner></app-partner>

<!-- Start Book Discover Area -->
<section class="book-discover ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="discover-content">
                    <h3>Discoverd Topics</h3>
                    <span>Take a look at topics are discovered in this book</span>
                    <p>Gain is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances.</p>

                    <ul>
                        <li><i class="icofont-check"></i> Design principles</li>
                        <li><i class="icofont-check"></i> Visual hierarchy</li>
                        <li><i class="icofont-check"></i> White space</li>
                        <li><i class="icofont-check"></i> Image selection</li>
                        <li><i class="icofont-check"></i> Responsive Design</li>
                        <li><i class="icofont-check"></i> Typography</li>
                        <li><i class="icofont-check"></i> Color theory</li>
                        <li><i class="icofont-check"></i> UI design</li>
                        <li><i class="icofont-check"></i> UX design</li>
                        <li><i class="icofont-check"></i> Real life projects</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="discover-img">
                    <img src="assets/img/book-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Book Discover Area -->

<!-- Start Features Area -->
<section class="features-area book-features ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="icofont-print"></i>
                    </div>
                    <h3>Printed Book</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="icofont-ui-edit"></i>
                    </div>
                    <h3>User Research</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="icofont-vehicle-delivery-van"></i>
                    </div>
                    <h3>Free Delivery</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="features-inner-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-inner-content">
                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-win-trophy"></i>
                            </div>
                            <h3>More than 5+ award achived</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-responsive"></i>
                            </div>
                            <h3>Read On Any Device</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>

                        <div class="features-item">
                            <div class="icon">
                                <i class="icofont-verification-check"></i>
                            </div>
                            <h3>Very high resolution (4K)</h3>
                            <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="assets/img/features-img1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>
<!-- End Features Area -->

<!-- Start App Funfacts Area -->
<section class="app-funfacts-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-book-alt"></i>
                    </div>
                    <h3><span class="count">5</span>M</h3>
                    <p>Copies Sold</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-coffee-alt"></i>
                    </div>
                    <h3><span class="count">1.5</span>M</h3>
                    <p>Cup Of Coffe</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-book-mark"></i>
                    </div>
                    <h3><span class="count">1</span>M</h3>
                    <p>Copies Released</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3">
                <div class="single-funfact">
                    <div class="icon">
                        <i class="icofont-ui-user"></i>
                    </div>
                    <h3><span class="count">50</span>K</h3>
                    <p>Happy Readers</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Funfacts Area -->

<!-- Start Screenshot Area -->
<section class="screenshot-area page-from-book ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Pages From Book</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="screenshot-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="screenshot-item">
                        <a href="#"><img src="assets/img/page1.jpg" alt="screenshot"></a>

                        <a href="assets/img/page1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="screenshot-item">
                        <a href="#"><img src="assets/img/page1.jpg" alt="screenshot"></a>

                        <a href="assets/img/page1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="screenshot-item">
                        <a href="#"><img src="assets/img/page1.jpg" alt="screenshot"></a>

                        <a href="assets/img/page1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="screenshot-item">
                        <a href="#"><img src="assets/img/page1.jpg" alt="screenshot"></a>

                        <a href="assets/img/page1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="screenshot-item">
                        <a href="#"><img src="assets/img/page1.jpg" alt="screenshot"></a>

                        <a href="assets/img/page1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="screenshot-item">
                        <a href="#"><img src="assets/img/page1.jpg" alt="screenshot"></a>

                        <a href="assets/img/page1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="screenshot-item">
                        <a href="#"><img src="assets/img/page1.jpg" alt="screenshot"></a>

                        <a href="assets/img/page1.jpg" class="popup-btn"><i class="icofont-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Screenshot Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Awesome Contributors</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="team-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="single-team-member-box">
                        <img src="assets/img/team-img1.jpg" alt="team">

                        <div class="team-content">
                            <h3>John Doe</h3>
                            <span>CEO</span>

                            <ul>
                                <li><a href="#" class="facebook"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#" class="instagram"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#" class="twitter"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-team-member-box">
                        <img src="assets/img/team-img2.jpg" alt="team">

                        <div class="team-content">
                            <h3>Steven Smith</h3>
                            <span>Developer</span>

                            <ul>
                                <li><a href="#" class="facebook"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#" class="instagram"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#" class="twitter"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-team-member-box">
                        <img src="assets/img/team-img3.jpg" alt="team">

                        <div class="team-content">
                            <h3>David Luiz</h3>
                            <span>Designer</span>

                            <ul>
                                <li><a href="#" class="facebook"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#" class="instagram"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#" class="twitter"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-team-member-box">
                        <img src="assets/img/team-img4.jpg" alt="team">

                        <div class="team-content">
                            <h3>Park Tim</h3>
                            <span>SEO Expert</span>

                            <ul>
                                <li><a href="#" class="facebook"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#" class="instagram"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#" class="twitter"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-team-member-box">
                        <img src="assets/img/team-img5.jpg" alt="team">

                        <div class="team-content">
                            <h3>Doglas Costa</h3>
                            <span>Manager</span>

                            <ul>
                                <li><a href="#" class="facebook"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#" class="instagram"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#" class="twitter"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#" class="linkedin"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start Pricing Area -->
<section class="pricing-area book-pricing ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Easy Pricing Plans</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">e-Book</h3>
                        <div class="price"><span class="dollar">$</span>6.00<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" href="#">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">PDF</h3>
                        <div class="price"><span class="dollar">$</span>12.99<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" href="#">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="pricing-table">
                    <div class="price-header">
                        <h3 class="title">Printed</h3>
                        <div class="price"><span class="dollar">$</span>19.00<span class="month">/Mo</span></div>
                    </div>

                    <div class="price-footer">
                        <a class="btn btn-primary" href="#">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <canvas id="canvas"></canvas>
</section>
<!-- End Pricing Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-100 bg-gray">
    <div class="container">
        <div class="section-title">
            <h2>Testimonials</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="feedback-slides owl-carousel owl-theme">
                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client1.jpg" alt="client">
                            <h3>Alit John</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client2.jpg" alt="client">
                            <h3>Alit John</h3>
                            <span>SEO Expert</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client3.jpg" alt="client">
                            <h3>Steven John</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="single-feedback">
                        <div class="client-info">
                            <img src="assets/img/client4.jpg" alt="client">
                            <h3>David Warner</h3>
                            <span>Web Developer</span>
                        </div>
                        <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <svg class="svg-feedback-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0,70 C30,130 70,50 100,70 L100,100 0,100 Z" fill="#ffffff"/>
    </svg>
</section>
<!-- End Feedback Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="blog-slides owl-carousel owl-theme">
                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img1.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 23, 2019</li>
                            </ul>
                            <h3><a href="#">The Most Popular New Business Apps</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img2.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 16, 2019</li>
                            </ul>
                            <h3><a href="#">The Best Marketing Management Tools</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 14, 2019</li>
                            </ul>
                            <h3><a href="#">3 WooCommerce Plugins to Boost Sales</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img4.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 06, 2019</li>
                            </ul>
                            <h3><a href="#">CakeMail Review – Design Custom Emails</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img5.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 04, 2019</li>
                            </ul>
                            <h3><a href="#">The Most Popular New Apps in 2019</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="single-blog-post">
                        <a href="#" class="post-image"><img src="assets/img/blog-img3.jpg" alt="blog-image"></a>

                        <div class="blog-post-content">
                            <ul>
                                <li><i class="icofont-user-male"></i> <a href="#">Admin</a></li>
                                <li><i class="icofont-wall-clock"></i> January 26, 2019</li>
                            </ul>
                            <h3><a href="#">The Fastest Growing Apps in 2019</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis massa, dapibus nec libero vitae.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-rounded-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Connect With Our Community</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <form class="newsletter-form" data-toggle="validator">
            <input type="email" class="form-control" placeholder="Your Email Address" name="EMAIL" required autocomplete="off">
            <button class="btn btn-primary" type="submit">Subscribe</button>
            <div id="validator-newsletter" class="form-result"></div>
        </form>
    </div>
</section>
<!-- End Subscribe Area -->

<!-- Start Platform Connect -->
<section class="platform-connect ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/mailchimp.png" alt="mailchimp">

                    <h3>Mail Chimp</h3>
                    <span>Send Mail</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/slack.png" alt="mailchimp">

                    <h3>Slack</h3>
                    <span>Messaging</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/twitter.png" alt="mailchimp">

                    <h3>Twitter</h3>
                    <span>Twitter Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>

            <div class="col-lg-3 col-md-6">
                <a href="#" class="box">
                    <img src="assets/img/instagram.png" alt="mailchimp">

                    <h3>Instagram</h3>
                    <span>News Feed</span>

                    <i class="icofont-bubble-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Platform Connect -->
