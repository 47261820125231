<!-- Start Sidebar Modal -->
<div class="sidebar-modal">
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="icofont-close"></i></span></button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a [routerLink]="['/home']" routerLinkActive="active"><img src="assets/img/logo.png" alt="logo"></a>
                    </h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Additional Links</h3>

                        <ul>
                            <li><a [routerLink]="['/payments']" [queryParams]="{type: 'pay-church'}" routerLinkActive="active">Pay Church</a></li>
                            <li><a [routerLink]="['/payments']" [queryParams]="{type: 'pay-merchant'}" routerLinkActive="active">Pay Merchant</a></li>
                            <li><a [routerLink]="['/payments']" [queryParams]="{type: 'pay-for-item'}" routerLinkActive="active">Pay For Item</a></li>
                            <li><a [routerLink]="['/payments']" [queryParams]="{type: 'pay-for-tv'}" routerLinkActive="active">Pay For DSTV/GOTV</a></li>
                            <li><a [routerLink]="['/payments']" [queryParams]="{type: 'buy-artime'}" routerLinkActive="active">Buy Airtime</a></li>
                            <li><a [routerLink]="['/payments']" [queryParams]="{type: 'group-save'}" routerLinkActive="active">Buy Insurance</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="icofont-google-map"></i> Address
                                <span>GA 101-2100 Accra ,Ghana.</span>
                            </li>
                            <li>
                                <i class="icofont-email"></i> Email
                                <span>info@paynowafrica.com</span>
                            </li>
                            <li>
                                <i class="icofont-phone"></i> Phone
                                <span>+123 456 7890</span>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <!-- <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li> -->
                            <li><a href="https://facebook.com/paynowafrica" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://twitter.com/paynowafrica" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="https://instagram.com/paynowafrica" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://linkedin.com/paynowafrica" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- modal-content -->
        </div>
        <!-- modal-dialog -->
    </div>
    <!-- modal -->
</div>
<!-- End Sidebar Modal -->