import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contact: any;
  constructor(private titleService: Title, private api: ApiService, private notify: NotificationsService) {
      this.titleService.setTitle('PayNow Africa - Contact Us');
  }

  ngOnInit(): void {
    this.init();
  }

  postContact() {
    // this.contact.to = 'harmonizerblinks@gmail.com';
    this.contact.subject =  'Paynow - Contact Form' + this.contact.subject;
    this.contact.body = `<h1>Hello,</h1><h2>below are the Contact details: </h2><p>Name: ${this.contact.name}<br>Email: ${this.contact.email}<br>Phone: ${this.contact.mobile}<br>Tax Identification Number: ${this.contact.tin}</p>`;
    this.api.postmail('contact',this.contact).subscribe((data: any) => {
      console.info(data);
      this.notify.success({title: 'Message Received'});
      this.init();
    });
  }

  init() {
    this.contact = { website: 'PayNow', name: '', mobile: '', email: '', tin: '', to: 'info@paynowafrica.com, sales@paynowafrica.com, gd@alias-solutions.net, harmonizerblinks@gmail.com', bcc: 'info@paynowafrica.com, sales@paynowafrica.com, gd@alias-solutions.net, harmonizerblinks@gmail.com',  subject: '', location: '', text: '', body: `<h1>Hello,</h1><h2>below are the details: </h2><p>Name:  $lname<br>Email: $email<br>Phone: $ctype<br>Gift Selected: $gtype<br>Address: $address<br>ID: $id<br></p>` };
  }

}
