import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, ) { }

  generateTags(config) {
    config = {
      title: 'PayNow Africa',
      description: 'PayNow can easily be accessed from any device and from anywhere because the Designs are extremely responsive. it was carefully developed to cover all areas of church management, including receive payment for tithe, offering, donation and raising funds for events. Use on any device, Send Automatic Birthday and Anniversary Wishes',
      image: 'https://creedcms.com/assets/img/favour.png',
      slug: '',
      ...config
    }

    
    this.meta.addTag({name: 'twitter:card', content: 'summary'});
    this.meta.addTag({name: 'twitter:site', content: 'Creed-CMS'});
    this.meta.addTag({name: 'twitter:title', content: config.title});
    this.meta.addTag({name: 'twitter:description', content: config.description});
    this.meta.addTag({name: 'twitter:image', content: config.image});

    this.meta.addTag({property: 'og:type', content: 'article'});
    this.meta.addTag({property: 'og:site_name', content: 'Creed-CMS'});
    this.meta.addTag({property: 'og:title', content: config.title});
    this.meta.addTag({property: 'og:description', content: config.description});
    this.meta.addTag({property: 'og:image', content: config.image});
    this.meta.addTag({property: 'og:url', content: `https//acyst.tech/${config.slug}`});
  }
}
