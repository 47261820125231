<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Privacy Policy</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>PayNow Privacy Policy</h2>
                        <div class="bar"></div>
                        <p>We belief that having access to financial services creates opportunity. PayNow is committed to democratizing payment services and empowering people and businesses to join and thrive in the global economy.</p>
                    </div>

                    <p>This privacy policy informs users of what PayNow does with the data collected. What information do we collect? When registering on our site, you may be asked to enter your business name, TIN number, e-mail address/mobile phone number.   What do we use your information for? Any information we collect from you may be used in one of the following ways. To personalize your experience: your information helps us to better respond to your individual needs. To improve our website: we continually strive to improve our website and mobile offerings based on the information and feedback we receive from you. To improve customer service: your information helps us to effectively respond to your customer service requests and support needs. To process transactions. To send periodic e-mails. To administer contests, promotions, surveys or other site features.   How do we protect your information? We implement a variety of security measures to maintain the safety of your personal information </p>

                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <img src="assets/img/paynow/privacy-policy.jpg" alt="image">
            </div>
        </div>
    </div>

    <div class="about-inner-area">
        <div class="container">
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="about-text">
                        <h4>Data Privacy</h4>
                        <p>PayNow Services, is committed to the legal framework of the Data Protection Act and duly registered as a Data Controller under the laws of Ghana with the certificate number XXXXXXXXXX</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>Two-factor authentication</h4>
                        <p>Critical transactions and operations on your PayNow account require a two-factor authentication before the transaction is approved for execution. Customers and merchants are not able to change this security measure.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>Data encryption</h4>
                        <p>All security codes, passwords and answers are safe. Why? We do not store them in clear text. As such, if by chance you do forget, you are provided with the option to reset.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>OWASP Security</h4>
                        <p>PayNow has undergone rigorous security testing required for public/private web application of its caliber. It is part of our commitment to ensure a consistent and effective user protection.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>256bit extended validation SSL encryption</h4>
                        <p>All communications between customers, merchants and third-party payment providers are encrypted with the most secure communication technology on the market.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>Fraud detection</h4>
                        <p>PayNow has an active and passive fraud detection system to analyze usage patterns and potential threats on the PayNow platform. This system works round the clock to ensure that your money is safe.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>Audit trails</h4>
                        <p>Every activity by merchants and end users of PayNow is logged for auditing purposes.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>Secure, user-defined cash-out procedures</h4>
                        <p>With PayNow, you the merchant remain in charge of withdrawals from your account. All cash-outs from PayNow can be initiated only by the merchant and are paid securely into the bank account of your choice.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>Security infrastructure</h4>
                        <p>Our infrastructure is hosted securely using top-of-the-industry standards with certificates to show how safe you are with us.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>How you can keep your account safe</h4>
                        <p>The security of your account depends on you keeping your account password confidential. You should not share your account name or password with anyone. Sharing this with others exposes your account to them.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>Refund</h4>
                        <p>PayNow will liaise with its service providers and endeavor to effect refund of a wrong transfer as soon as possible, but in any event not later than 20 days from the date on which a request was made.</p>
                        <p>We do not have any obligation to cancel any transfer instructions and refund to the subscriber in the event of any dispute with any designated payee.</p>
                        <p>All terms and conditions in relation to refunds for the various service providers (mobile money institutions) will apply.</p>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 mt-3">
                    <div class="about-text">
                        <h4>Chargeback</h4>
                        <p>All complaints received, would be thoroughly investigated, and if a refund is due, our refund policy shall apply.</p>
                        <p>However, if a complaint is a chargeback, you would be directed to your bank to have the issue addressed. Most banks have a chargeback policy which would apply.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- End About Area -->
